import React from 'react';
import { PropTypes } from 'prop-types';

import { FormattedMessage } from 'react-intl';

import * as UI from '../../../../ui';

import GaleryRow from './GalleryRow';

import { rows } from './content';

const { DynamicContainer, ContainerType, Text, TextTypes } = UI;

const GallerySection = ({ rules }) => (
    <DynamicContainer
        isFluid
        type={ContainerType.GRID}
        as={ContainerType.SECTION}
        extend={{ container: rules.extendContainer }}
    >
        <Text extend={{ text: rules.extendText }} columns={12} type={TextTypes.SECTION}>
            <FormattedMessage id="aboutUsPage.gallery.title" />
        </Text>
        {rows.map((row, index) => (
            <GaleryRow key={index} reverse={index % 2 !== 0} images={row.images} />
        ))}
    </DynamicContainer>
);

GallerySection.propTypes = {
    rules: PropTypes.shape().isRequired,
};

export default GallerySection;
