import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

const { DynamicContainer, ContainerType, Quote, Text, TextTypes } = UI;

const MissionSection = ({ styles, rules, reference }) => {
    return (
        <DynamicContainer as={ContainerType.SECTION} isFluid ref={reference} className={styles.container}>
            <Text extend={rules.extendText} type={TextTypes.SECTION}>
                <FormattedMessage id="aboutUsPage.mission.title" />
            </Text>
            <div className={styles.container}>
                <Quote message="aboutUsPage.mission.quote" />
            </div>
        </DynamicContainer>
    );
};

MissionSection.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape().isRequired,
};

export default MissionSection;
