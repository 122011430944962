import React from 'react';
import { useFelaEnhanced } from '@ackee/fela';

import { content } from './content';
import { SingleLeader } from './SingleLeader';
import * as felaRules from './Leaders.rules';

import * as UI from '../../../../../ui';
import { FormattedMessage } from 'modules/ui/dependencies';

const { DynamicContainer, ContainerType, Title, TitleTypes, Text, TextTypes, FormattedMessageWithLinks } = UI;

export const Leaders = () => {
    const { styles, rules } = useFelaEnhanced(felaRules);

    return (
        <div>
            <div >
            {/* @ts-expect-error */}
            <Title extend={{title: rules.subtitle}} as={TitleTypes.H3} type={TitleTypes.H3_UPPERCASE}>
                <FormattedMessage id="aboutUsPage.leadership.subtitle" />
            </Title>
            </div>
            <div className={styles.container}>
                {content.map(item => (
                    <SingleLeader key={item.id} content={item} />
                ))}
            </div>
        </div>
    );
};
