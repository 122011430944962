export const extendTitle = {
    title: ({ theme }) => ({
        marginLeft: '2rem',
        marginTop: '1rem',
        tablet: {
            ...theme.fonts.heroNewBold,
            marginLeft: '2.3125rem',
            fontSize: '2rem',
            lineHeight: '2.625rem',
        },
    }),
};

export const extendText = {
    text: () => ({
        marginLeft: '2rem',
        marginTop: '1.5rem',
        width: '96.21451%',
        fontSize: '1.125rem',
        lineHeight: '1.75rem',
        fontWeight: 500,
        tablet: {
            marginLeft: '2.3125rem',
        },
    }),
};

export const extendContainer = {
    container: ({ theme: { colors }, isLast }) => ({
        position: 'relative',
        overflow: 'visible',
        paddingBottom: '4rem',
        marginBottom: !isLast ? '3rem' : 0,

        tabletLarge: {
            marginBottom: 'unset',
            paddingRight: '3rem',
            paddingBottom: '1rem',
            marginRight: !isLast ? '1.5rem' : 0,
            borderBottom: 'none',

            ':last-child': {
                paddingRight: 0,
                marginRight: 0,
                borderRight: 0,
            },
        },
    }),
};

export const extendDividerContainer = {
    container: () => ({
        height: '13.3125rem',
    }),
};
