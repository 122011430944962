import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

const { DynamicContainer, ContainerType, Quote, Text, TextTypes } = UI;

const VisionSection = ({ styles, rules, reference, theme }) => {
    const { colors } = theme;

    return (
        <DynamicContainer extend={rules.extendContainer} as={ContainerType.SECTION} dark>
            <DynamicContainer isFluid ref={reference}>
                <Text extend={rules.extendText} type={TextTypes.SECTION}>
                    <FormattedMessage type={TextTypes.SECTION} id="aboutUsPage.vision.title" />
                </Text>
                <div className={styles.container}>
                    <Quote
                        extend={{ extendText: rules.quoteText }}
                        message="aboutUsPage.vision.quote"
                        color={colors.white}
                    />
                </div>
            </DynamicContainer>
        </DynamicContainer>
    );
};

VisionSection.propTypes = {
    styles: PropTypes.shape({
        container: PropTypes.string.isRequired,
    }).isRequired,
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape(),
    theme: PropTypes.shape().isRequired,
};

VisionSection.defaultProps = {
    reference: null,
};

export default VisionSection;
