import bg from '../../../../../../static/images/loader/background.png';

export const extendContainer = {
    container: ({ theme }) => ({
        backgroundImage: `url(${bg})`,
        backgroundColor: theme.colors.darkBlue,
        backgroundSize: 'contain',
        position: 'relative',
    }),
};

export const container = {
    position: 'relative',
    zIndex: 2,

    tabletLarge: {
        padding: '4rem 13.25rem 4.5rem 13.25rem',
    },
};

export const extendText = {
    text: ({ theme: { colors } }) => ({
        color: colors.white,
    }),
};

export const quoteText = () => ({
    fontSize: '2.5rem',
    lineHeight: '3rem',
    tablet: {
        fontSize: '3rem',
        lineHeight: '3.5rem',
    },
});
