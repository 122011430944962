export const extendContainer = {
    container: () => ({
        paddingBottom: 0,
        paddingTop: '3.25rem',

        tabletLarge: {
            paddingTop: '10rem',
            paddingBottom: 0,
        },
    }),
};

export const divider = ({ theme: { colors } }) => ({
    display: 'none',

    tabletLarge: {
        display: 'block',
        gridColumn: 'auto / span 12',
        height: '0.125rem',
        backgroundColor: colors.dividerGrey,
        marginTop: '10rem',
    },
});

export const extendText = {
    text: ({ theme: { colors } }) => ({
        marginBottom: '5rem',
    }),
};

export const smallSection = () => ({
    marginTop: '10.25rem',
});
