import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../../ui';

import Image from './Image';

const { DynamicContainer } = UI;

const GalleryRow = ({ reverse, images }) => (
    <>
        <DynamicContainer columns={reverse ? 8 : 4}>
            <Image image={images[0]} />
        </DynamicContainer>
        <DynamicContainer columns={reverse ? 4 : 8}>
            <Image image={images[1]} />
        </DynamicContainer>
    </>
);

GalleryRow.propTypes = {
    reverse: PropTypes.bool.isRequired,
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string,
            id: PropTypes.string,
        }),
    ).isRequired,
};

export default GalleryRow;
