import React from 'react';
import { PropTypes } from 'prop-types';

const Overlay = ({ styles }) => (
    <div className={styles.container}>
        <svg width="796" height="437" viewBox="0 0 796 437" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M269 437H259L269 427V437Z" fill="#FF00FF" />
            <path d="M518 437L528 427H518V437Z" fill="#FF00FF" />
            <rect x="269" y="427" width="249" height="10" fill="#FF00FF" />
            <path d="M10 10H0L10 0V10Z" fill="#FF00FF" />
            <path d="M165 10L175 0H165V10Z" fill="#FF00FF" />
            <rect x="10" width="155" height="10" fill="#FF00FF" />
            <path d="M706 283H696L706 273V283Z" fill="#FF00FF" />
            <path d="M786 283L796 273H786V283Z" fill="#FF00FF" />
            <rect x="706" y="273" width="80" height="10" fill="#FF00FF" />
        </svg>
    </div>
);

Overlay.propTypes = {
    styles: PropTypes.shape().isRequired,
};

export default Overlay;
