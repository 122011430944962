//  this cannot be imported from dependencies
import { graphql, useStaticQuery } from 'gatsby';

import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import { useIntl } from 'react-intl';

import { useFelaEnhanced } from '@ackee/fela';

import * as UI from '../../../../../../ui';
import type { Leader } from '../content';
import * as felaRules from './SingleLeader.rules';

const { Title, Text, TextTypes } = UI;

export interface SingleLeaderProps {
    content: Leader;
}

export const SingleLeader = ({ content }: SingleLeaderProps) => {
    const { img, name, position, shadowColor } = content;

    const { styles, rules } = useFelaEnhanced(felaRules, { shadowColor });

    const intl = useIntl();
    const { formatMessage } = intl;

    const formattedName = formatMessage({ id: name });
    const formattedPosition = formatMessage({ id: position });

    const images = useStaticQuery<Queries.Query>(graphql`
        query {
            allFile(filter: { relativeDirectory: { eq: "about-us/leaders" } }) {
                nodes {
                    base
                    relativePath
                    childImageSharp {
                        fluid(quality: 90, cropFocus: SOUTHWEST, maxWidth: 608, maxHeight: 608) {
                            ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                    }
                }
            }
        }
    `);

    const match = React.useMemo(
        () => images.allFile.nodes.find(({ relativePath }) => img === relativePath),
        [images, img],
    );

    if (!match?.childImageSharp?.fluid) return null;

    const {
        childImageSharp: { fluid },
    } = match;

    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <Img alt={formattedName} fluid={fluid as FluidObject} />
            </div>
            <div className={styles.textContainer}>
                {/* @ts-expect-error */}
                <Title extend={{ title: rules.extendTitle }}>{formattedName}</Title>
                {/* @ts-expect-error */}
                <Text extend={{ text: rules.extendText }} type={TextTypes.NORMAL}>
                    {formattedPosition}
                </Text>
            </div>
        </div>
    );
};
