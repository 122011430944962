import type { TRuleWithTheme } from '@ackee/fela';

import type { Leader } from '../content';

export const container: TRuleWithTheme = () => ({
    marginBottom: '2rem',
    flexBasis: '25%',

    desktopLarge: {
        flexBasis: 'unset',
    },
});

export const imageContainer: TRuleWithTheme<{ shadowColor: Leader['shadowColor'] }> = ({ shadowColor }) => ({
    width: '19rem',
    height: '19rem',

    tablet: {
        width: '10rem',
        height: '10rem',
    },

    tabletLarge: {
        width: '12rem',
        height: '12rem',
    },

    desktopLarge: {
        width: '18rem',
        height: '18rem',
    },

    boxShadow: `0.5rem 0.5rem 0rem 0rem ${shadowColor}`,
});

export const extendTitle: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.white,
    marginBottom: '0.375rem',
    marginTop: 0,
    fontSize: '1rem',
});

export const extendText: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.lightGrey,
    fontSize: '0.875rem',
    textTransform: 'none',
    fontWeight: 500,
    fontStyle: 'italic',
    lineHeight: '1.125rem',
});

export const textContainer: TRuleWithTheme = () => ({
    marginTop: '1.5rem',
});
