import type { MessageKey } from 'modules/intl';

interface Row {
    images: {
        src: string;
        id: MessageKey;
    }[];
}

export const rows: Row[] = [
    {
        images: [
            {
                src: `about-us/gallery/1.jpg`,
                id: `aboutUsPage.gallery.image.alt.1`,
            },
            {
                src: `about-us/gallery/2.jpg`,
                id: `aboutUsPage.gallery.image.alt.2`,
            },
        ],
    },
    {
        images: [
            {
                src: `about-us/gallery/3.jpg`,
                id: `aboutUsPage.gallery.image.alt.3`,
            },
            {
                src: `about-us/gallery/4.jpg`,
                id: `aboutUsPage.gallery.image.alt.4`,
            },
        ],
    },
    {
        images: [
            {
                src: `about-us/gallery/5.jpg`,
                id: `aboutUsPage.gallery.image.alt.5`,
            },
            {
                src: `about-us/gallery/6.jpg`,
                id: `aboutUsPage.gallery.image.alt.6`,
            },
        ],
    },
];
