import React from 'react';
import { PropTypes } from 'prop-types';
import * as UI from '../../../../ui';
import { FormattedMessage } from 'react-intl';

import { content } from './content';

import SingleNumber from './SingleNumber';

const { DynamicContainer, ContainerType, Text, TextTypes } = UI;

const NumbersSection = ({ styles, rules, reference }) => (
    <DynamicContainer ref={reference} as={ContainerType.SECTION} columns={12}>
        <DynamicContainer isFluid type={ContainerType.GRID} extend={rules.extendContainer}>
            <Text as="h2" extend={rules.extendText} columns={12} type={TextTypes.SECTION}>
                <FormattedMessage id="aboutUsPage.numbers.sectionTitle" />
            </Text>

            {content.map((item, i) => (
                <SingleNumber key={item.title} content={item} isLast={i === content.length - 1} />
            ))}

            <div className={styles.divider} />
        </DynamicContainer>
    </DynamicContainer>
);

NumbersSection.propTypes = {
    styles: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
    reference: PropTypes.shape(),
};

NumbersSection.defaultProps = {
    reference: null,
};

export default NumbersSection;
