export const container = () => ({
    position: 'absolute',
    zIndex: -1,
    transformOrigin: 'top left',
    transform: 'scale(0.5)',
    width: '24.875rem',
    top: '5rem',
    right: '1rem',

    tablet: {
        transform: 'scale(0.8)',
        width: '39.8rem',
        top: '7rem',
        right: '2rem',
    },

    tabletLarge: {
        transform: 'scale(1.0)',
        width: '49.75rem',
        top: '12.0625rem',
        right: '11.8125rem',
    },
});
