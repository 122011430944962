import React from 'react';

import { Layout } from '../modules/ui';
import { AboutUsPage } from '../modules/about-us-page/components';
import Seo from '../components/SEO';
import * as Constants from '../constants';

const About = () => {
    const footerRef = React.useRef();

    return (
        <>
            <Seo pageId={Constants.pages.ABOUT_US} />
            <Layout footerRef={footerRef}>
                <AboutUsPage footerRef={footerRef} />
            </Layout>
        </>
    );
};

export default About;
