import { usePageContext } from 'gatsby-theme-localized';

import React from 'react';
import * as UI from '../../../../ui';

import { content } from './content';

const { DynamicContainer, ContainerType, Carousel, CarouselTypes, LazyLoadContainer } = UI;

const TestimonialsSection = ({ reference, rules }) => {
    const { locale } = usePageContext();

    return (
        <DynamicContainer ref={reference} as={ContainerType.SECTION}>
            <LazyLoadContainer lazyLoaded>
                <Carousel
                    extend={{ container: rules.container }}
                    content={content[locale]}
                    type={CarouselTypes.TESTIMONIALS_CLUTCH}
                    containerEventId="carousel-root-clutch"
                />
            </LazyLoadContainer>
        </DynamicContainer>
    );
};

export default TestimonialsSection;
