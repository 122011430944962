export const content = [
    {
        title: 'aboutUsPage.numbers.1.title',
        description: 'aboutUsPage.numbers.1.description',
    },
    {
        title: 'aboutUsPage.numbers.2.title',
        description: 'aboutUsPage.numbers.2.description',
    },
    {
        title: 'aboutUsPage.numbers.3.title',
        description: 'aboutUsPage.numbers.3.description',
    },
];
