import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PropTypes } from 'prop-types';

import * as UI from '../../../../../ui';

const { Title, TitleTypes, Text, DynamicContainer, FormattedMessageWithLinks, SlantedDivider } = UI;

const SingleService = ({ content, rules, isLast }) => {
    const { title, description } = content;

    return (
        <DynamicContainer columns={4} isLast={isLast} extend={rules.extendContainer}>
            <SlantedDivider extend={rules.extendDividerContainer} />
            <Title as={TitleTypes.H3} type={TitleTypes.H4} extend={rules.extendTitle}>
                <FormattedMessage id={title} />
            </Title>
            <Text extend={rules.extendText}>
                <FormattedMessageWithLinks id={description} />
            </Text>
        </DynamicContainer>
    );
};

SingleService.propTypes = {
    isLast: PropTypes.bool.isRequired,
    content: PropTypes.shape().isRequired,
    rules: PropTypes.shape().isRequired,
};

export default SingleService;
