export const extendContainer = () => ({
    gridRowGap: '1.5rem',

    tabletLarge: {
        paddingTop: 0,
    },
});

export const extendText = ({ theme: { colors } }) => ({
    color: colors.black,
});
