const testimonialImage1 = 'people/img_person_hopi_shanil.jpg';
const testimonialImage2 = 'people/img_person_zonky.jpg';
const testimonialImage3 = 'people/img_person_placeholder.jpg';

const testimonialImageDe1 = 'people/img_person_wwf.jpg';
const testimonialImageDe2 = 'people/img_person_klicktipp.jpg';
const testimonialImageDe3 = 'people/img_person_placeholder.jpg';

const testimonialImages = {
    cs: {
        1: testimonialImage1,
        2: testimonialImage2,
        3: testimonialImage3,
    },
    en: {
        1: testimonialImage1,
        2: testimonialImage2,
        3: testimonialImage3,
    },
    de: {
        1: testimonialImageDe1,
        2: testimonialImageDe2,
        3: testimonialImageDe3,
    },
};

const content = {
    cs: [],
    en: [],
    de: [],
};

const ids = Array.from(Array(4).keys());

Object.keys(content).forEach(key => {
    const items = ids.map(id => {
        const contentID = id + 1;
        return {
            id,
            src: testimonialImages[key][contentID],
            alt: `aboutUsPage.testimonials.${key}.image${contentID}.alt`,
            testimonial: `aboutUsPage.testimonials.${key}.testimonial${contentID}`,
            name: `aboutUsPage.testimonials.${key}.name${contentID}`,
            company: `aboutUsPage.testimonials.${key}.company${contentID}`,
            link: `aboutUsPage.testimonials.${key}.link${contentID}`,
        };
    });

    content[key] = items;
});

export { content };
