import type { TRuleWithTheme } from '@ackee/fela';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '81rem',
    position: 'relative',
    zIndex: 2,
    marginTop: '3rem',
    flexWrap: 'wrap',
    
    tablet: {
        flexDirection: 'row',
        gap: '48px',
    },
});

export const subtitle: TRuleWithTheme = ({ theme: { colors } }) => ({
    color: colors.white,
    marginBottom: 0,
    position: 'relative',
    zIndex: 2,
    fontSize: '2rem',
    marginTop: '6rem'
})