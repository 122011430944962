export const container = ({ theme: { colors } }) => ({
    backgroundColor: colors.bgGrey,
    padding: '2rem 2rem 2.5rem 2rem',

    tablet: {
        padding: '4rem 6.875rem 4.5rem 7.0625rem',
    },
});

export const extendText = {
    text: ({ theme: { colors } }) => ({
        color: colors.black,
    }),
};
