import bg from '../../../../../../static/images/loader/background.png';

export const extendContainer = {
    container: ({ theme }) => ({
        backgroundImage: `url(${bg})`,
        backgroundColor: theme.colors.darkBlue,
        backgroundSize: 'contain',
        position: 'relative',
    }),
};

export const extendTitle = {
    title: ({ theme: { colors } }) => ({
        color: colors.white,
        marginTop: 0,
        position: 'relative',
        zIndex: 2,
    }),
};

export const extendText = {
    text: ({ theme: { colors } }) => ({
        color: colors.white,
        maxWidth: '54.25rem',
        position: 'relative',
        zIndex: 2,
        marginBottom: '2rem'
    }),
};

export const link = ({ theme: { colors } }) => ({
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: colors.cyan,
    textDecoration: 'none',
    borderBottom: `1px solid ${colors.blue}`,
    willChange: 'color',
    transition: 'cubic-bezier(0.4, 0.0, 0.2, 1) color 0.3s',

    ':hover': {
        color: colors.fuchsia,
    },
});

export const textLink = ({ theme: { colors } }) => ({
    color: colors.cyan,

    ':visited': {
        color: colors.cyan,
    },
});
