import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FormattedMessage } from 'react-intl';

import { ArticlesList } from 'modules/pr-articles';

import HeroSection from '../../../hero-section';
import * as UI from '../../../ui';
import { InquirySection } from '../../../inquiry';

import NumbersSection from './NumbersSection';
import TestimonialsSection from './TestimonialsSection';
import LeadershipSection from './LeadershipSection';
import MissionSection from './MissionSection';
// import ValuesSection from './ValuesSection';
import GallerySection from './GallerySection';
import VisionSection from './VisionSection';

import HeroImage from './HeroImage';

import * as styles from './AboutUsPage.styles';

const { SectionNavigation, sectionTitles, TitleTypes, FormattedMessageWithLinks } = UI;

const AboutUsPage = ({ footerRef }) => {
    const {
        allStrapiPrArticle: { totalCount },
    } = useStaticQuery(graphql`
        query StrapiPrArticlesCount {
            allStrapiPrArticle {
                totalCount
            }
        }
    `);

    const hasAnyPrArticles = totalCount > 0;

    const heroRef = React.useRef();
    const leadershipRef = React.useRef();
    const missionRef = React.useRef();
    const wroteRef = React.useRef();

    const sections = {
        hero: {
            ref: heroRef,
            title: sectionTitles.hero.aboutUsPage,
            id: 1,
        },
        ...(hasAnyPrArticles && {
            wrote: {
                ref: wroteRef,
                title: sectionTitles.wrote.aboutUsPage,
                id: 4,
            },
        }),
        leadership: {
            ref: leadershipRef,
            title: sectionTitles.leadership.aboutUsPage,
            id: 2,
        },
        mission: {
            ref: missionRef,
            title: sectionTitles.mission.aboutUsPage,
            id: 3,
        },
        footer: {
            ref: footerRef,
            title: sectionTitles.footer,
            id: 6,
        },
    };

    return (
        <>
            <HeroSection
                ref={heroRef}
                title={<FormattedMessage id="aboutUsPage.hero.title" />}
                titleAs={TitleTypes.H1}
                sectionTitle={<FormattedMessage id="aboutUsPage.hero.sectionTitle" />}
                description={<FormattedMessageWithLinks id="aboutUsPage.hero.description" />}
                customContent={<HeroImage src="about-us/hero/ackee.png" alt="aboutUsPage.hero.image.alt" />}
                showImages
                extend={{ descriptionContainer: styles.descriptionContainer }}
            />

            <NumbersSection />
            <TestimonialsSection />
            <ArticlesList ref={wroteRef} />
            <LeadershipSection reference={leadershipRef} />
            <MissionSection reference={missionRef} />
            {/* Uncomment when copy ready */}
            {/* <ValuesSection reference={valuesRef} /> */}
            <GallerySection />
            <VisionSection />
            <InquirySection />
            <SectionNavigation sections={sections} />
        </>
    );
};

export default AboutUsPage;
