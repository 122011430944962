export const imageContainer = () => ({
    width: '47.375rem',
    maxWidth: '70%',
    top: '0rem',
    right: '0rem',
    position: 'absolute',
    zIndex: -2,

    tabletLarge: {
        display: 'block',
    },
});
